<template>
<div class="page xhy-temp classify_select_box">
    <van-nav-bar class="header">
        <div class="left" slot="left" @click="goback">取消</div>
        <div class="title" slot="title">选择对象</div>
    </van-nav-bar>
    <div class="main-body">
        <van-cell-group gutter="10px">
            <van-cell is-link style="height: 30px" @click.native="goSelectObject(1)">
                <span slot="title">部门结构</span>
            </van-cell>
            <van-cell is-link style="height: 30px" @click.native="goSelectObject(2)" v-if="isShowPost">
                <span slot="title">岗位类别</span>
            </van-cell>
            <van-cell is-link style="height: 30px" @click.native="goSelectObject(3)">
                <span slot="title">人员分组</span>
            </van-cell>
        </van-cell-group>
    </div>
</div>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            userinfo: null,
            app_type: "",
            isShowPost: false
        };
    },
    mounted() {
        this.initData();
    },
    activated() {
        this.initData();
    },
    methods: {
        /**
         * @description 回退事件
         * @param
         * @author: zhangwencai
         * @Date: 2019年5月8日13:40:16
         * @LastEditTime:2019年5月8日13:40:25
         * @LastEditors:
         */
        goback() {
            this.$router.go(-1);
        },
        /**
         * @description 初始化数据
         * @param
         * @author: zhangwencai
         * @Date: 2019年5月8日13:40:30
         * @LastEditTime:2019年5月8日13:40:33
         * @LastEditors:
         */
        initData() {
            var state = {
                title: "title",
                url: "#"
            };

            window.history.pushState(state, "title3333", "#");
            window.removeEventListener("popstate", function(e){
                alert("remove")
            })
            window.addEventListener("popstate", function (e) {
                alert("ok")
            }, false);

            // let self = this;
            // //判断是是否加载完成数据;true:表示加载完成，false：表示未加载成功
            // let _flag = this.$tool.isLoadData(this.initData);
            // if (!_flag) {
            //     //未加载完成数据则继续回调
            //     return;
            // }
            // //注册安卓返回键
            // this.$tool.registerBack(this._goback);
            // self.userinfo = this.$storageservice.getUserInfo();
            // console.log('userinfo--',this.userinfo);
            // this.app_type = this.$tool.getParam("p_type");
            // this.checkOrgIsgroup();
        },
        /**
         * @description 跳转对应的分类的选择人员
         *  @param {index} 下标
         * @author: zhangwencai
         * @Date: 2019年5月15日15:14:55
         * @LastEditTime:
         * @LastEditors:
         */
        goSelectObject(index) {
            console.log(index);
            this.$tool.navigationX({
                scope: this,
                path: "SelectPeople2",
                params: {
                    isFirst: true
                }
            }); // 调转
            // 层级数字为1
            this.$store.commit("setHierarchyNum", 1);
            this.$store.commit("SelectPerson2/SET_TYPENUM", index);
            this.unRegisterBack();
        },
        checkOrgIsvgroup(call) {
            let params = {
                orgId: this.userinfo['OrganizeId']
            }
            this.$vux.loading.show({
                text: "Loading"
            });
            this.$tool.ajax(this, this.$commonapi.checkOrgIsgroup, "get", params, null, res => {
                this.$vux.loading.hide();
                if (res.status == 1) {
                    if (['van-group', 'Area', 'Heightvan-group'].includes(res.results)) {
                        this.isShowPost = true;
                    }else {
                        this.isShowPost = false;
                    }
                }
            }, 'token')
        },
    }
};
</script>

<style lang="scss" scoped>
.classify_select_box {
    position: relative;
    height: 100%;

    .header {
        /deep/ .vux-header-left {
            top: 14px;
            left: 15px;
            display: inline-flex;
            align-items: center;
        }

        .left {
            font-size: 16px;
            color: #1aa5fe;
        }

        .title {
            color: #323941;
        }
    }

    .main-body {
        height: auto;
    }
}
</style>
